import { useState } from "react";
import { Divider, IconButton, Icon } from "rsuite";

export const Foldable = ({ children }) => {
  const [isExpanded, setIsExpanded] = useState(false);
  return (
    <>
      {isExpanded && children}
      <Divider className="col-xs-12 foldable-divider">
        <IconButton
          icon={<Icon rotate={90 * (isExpanded ? 1 : -1)} icon="left" />}
          onClick={() => setIsExpanded((v) => !v)}
          color="blue"
          size="sm"
        />
      </Divider>
    </>
  );
};
