import React, { Fragment, useCallback, useEffect } from "react";
import { Panel, Button, Col } from "rsuite";
import { formatPriceWithCommas } from "../helper/utility";

export const OfferPreviewPricingOptions = ({
    selectedTime,
    dateHeader,
    timeInfos,
    isAccommodationTab,
    onSelectTime,
    preselectFirstEntry = false,
}) => {
    useEffect(() => {
        if (!preselectFirstEntry || selectedTime) {
            return;
        }
        const timeInfo = timeInfos?.[0]?.times?.[0];
        if (timeInfo) {
            onSelectTime(
                timeInfos[0],
                isAccommodationTab ? { date: timeInfo.date } : timeInfo
            );
        }
    }, [
        timeInfos,
        selectedTime,
        preselectFirstEntry,
        onSelectTime,
        isAccommodationTab,
    ]);

    const TimeAndPrices = timeInfos.map((timeInfo, index) => (
        <Fragment key={index}>
            <div className="preview-view-pricing-table__wrapper">
                <span className="preview-view-pricing-table__price">
                    {`${formatPriceWithCommas(
                        timeInfo.price,
                        timeInfo.currency
                    )}`}
                </span>
                <span className="preview-view-pricing-table__time-buttons">
                    {timeInfo.times.map((time, idx) => (
                        <Button
                            className="preview-view-pricing-table-bottom-pannel-button"
                            size="xs"
                            color={
                                selectedTime?.date === time.date
                                    ? "green"
                                    : null
                            }
                            key={idx}
                            onClick={() => {
                                onSelectTime(timeInfo, time);
                            }}
                            style={{ margin: "5px" }}
                        >
                            <span className="preview-view-pricing-option__time">
                                {time.hour}
                            </span>
                        </Button>
                    ))}
                </span>
            </div>
        </Fragment>
    ));
    const timeInfo = timeInfos[0]?.times?.[0];
    const formattedPrice = formatPriceWithCommas(
        timeInfos[0]?.price,
        timeInfos[0]?.currency
    );
    return (
        <Col className="preview-view-pricing-table-time-panel">
            <Panel className="preview-view-pricing-table-top-panel">
                {dateHeader}
            </Panel>
            {isAccommodationTab && (
                <Button
                    className="preview-view-pricing-table-top-panel preview-view-pricing-table-bottom-pannel-button"
                    block
                    color={
                        selectedTime?.date === timeInfo.date ? "green" : null
                    }
                    onClick={() => {
                        onSelectTime(timeInfos[0], { date: timeInfo.date });
                    }}
                >
                    <span>{formattedPrice} pro Person</span>
                </Button>
            )}
            {!isAccommodationTab && (
                <Panel
                    bordered
                    className="preview-view-pricing-table-bottom-pannel"
                >
                    {TimeAndPrices}
                </Panel>
            )}
        </Col>
    );
};
