import React, { useContext } from "react";
import clsx from "clsx";
import AdminIcon from "@rsuite/icons/Admin";
import { OrderContext } from "../helper/order-context";
import { sum } from "../../../money/money-tools";
import { formatPriceWithCommas } from "../helper/utility";

const PriceInfo = ({ label, prices, participantsCount }) => {
  const pricesSum = sum(prices);
  const pricePerPerson = formatPriceWithCommas(
    !participantsCount
      ? pricesSum?.amount
      : (pricesSum?.amount ?? 0) / participantsCount,
    pricesSum?.currency
  );
  return (
    <div>
      <span>{label}</span>
      <span>{pricePerPerson}</span>
      <span>
        <AdminIcon />
      </span>
    </div>
  );
};

export const OfferPricing = ({ className }) => {
  const { orderItems, participantsOrder: totalParticipants } = useContext(
    OrderContext
  );
  const requiredOrderItems = orderItems.filter((order) => !order.optional);
  const isPriceAvailableToDisplay = requiredOrderItems.length > 0;

  const purchasePrices = requiredOrderItems.map(
    (i) => i.offerCalculatedItemPriceWithOptionsPerPerson
  );
  const retailPrices = requiredOrderItems.map((i) => i.itemPriceWithOptions);
  return isPriceAvailableToDisplay ? (
    <div className={clsx(className)}>
      <PriceInfo label={"EK p.P:"} prices={purchasePrices} />
      <PriceInfo
        label={"VK p.P:"}
        prices={retailPrices}
        participantsCount={totalParticipants}
      />
    </div>
  ) : null;
};
