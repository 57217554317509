import React from "react";
import { Row } from "rsuite";
import _ from "lodash";
import { OfferPreviewPricingOptions } from "./offer-preview-pricing-options";

export const OfferPreviewPricing = ({
    selectedTime,
    isAccommodationTab,
    timeAndPriceOptions,
    onSelectTime,
}) => {
    const { timeAndPrice1, timeAndPrice2, day1, day2 } = timeAndPriceOptions;
    const hasFirstOption = _.size(timeAndPrice1) !== 0;
    const hasSecondOption = _.size(timeAndPrice2) !== 0;
    return (
        <>
            <Row className="preview-view-pricing-table">
                {hasFirstOption && (
                    <OfferPreviewPricingOptions
                        selectedTime={selectedTime}
                        dateHeader={day1}
                        timeInfos={timeAndPrice1}
                        isAccommodationTab={isAccommodationTab}
                        onSelectTime={onSelectTime}
                        preselectFirstEntry
                    />
                )}
                {hasSecondOption && (
                    <OfferPreviewPricingOptions
                        selectedTime={selectedTime}
                        dateHeader={day2}
                        timeInfos={timeAndPrice2}
                        isAccommodationTab={isAccommodationTab}
                        onSelectTime={onSelectTime}
                        preselectFirstEntry={!hasFirstOption}
                    />
                )}
                {!hasFirstOption && !hasSecondOption && (
                    <div>"Keine Preise verfügbar"</div>
                )}
            </Row>
        </>
    );
};
